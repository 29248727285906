/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
.navihome,
.cb-home {
  width: 259px;
}
.footer {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}
h1 {
  margin: 0 1%;
}
.togglenavigation {
  left: 3%;
}
.copyright {
  margin-bottom: 56px;
}
.meta.tell {
  right: 0;
}
#services {
  float: right;
  display: block;
  width: auto;
  margin: 12px 0 56px 0;
}
#services .meta {
  font-size: 10px;
  margin-right: 0;
}
#services .meta.firm {
  display: none;
}
.cb-page-last #services .meta.firm {
  display: inline;
}
#services .meta.auth {
  display: none;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.area .part,
.area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.area .tiny {
  width: 48%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96%;
}
.area > .slim .tiny {
  width: 96%;
}
.cb-page-layout2 .side {
  width: 100%;
}
.cb-page-layout2 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .side h2,
.cb-page-layout2 .side .foot {
  margin-right: 1.5%;
  margin-left: 1.5%;
}
.cb-page-layout2 .side .part,
.cb-page-layout2 .side > .grid table {
  margin-right: 1.5%;
  margin-left: 1.5%;
  width: 97%;
}
.cb-page-layout2 .side .tiny {
  width: 47%;
}
.cb-page-layout2 .side > .slim {
  width: 50%;
}
.cb-page-layout2 .side > .slim h2,
.cb-page-layout2 .side > .slim .foot,
.cb-page-layout2 .side > .slim .part,
.cb-page-layout2 .side > .slim.grid table {
  margin-right: 3%;
  margin-left: 3%;
}
.cb-page-layout2 .side > .slim .part,
.cb-page-layout2 .side > .slim.grid table {
  width: 94%;
}
.cb-page-layout2 .side > .slim .tiny {
  width: 94%;
}
.cb-page-layout4 .main {
  width: 100%;
}
.cb-page-layout4 .main > .unit {
  margin-right: 1.06382979%;
  margin-left: 1.06382979%;
  width: 97.87234043%;
}
.cb-page-layout4 .main h2,
.cb-page-layout4 .main .foot {
  margin-right: 2.17391304%;
  margin-left: 2.17391304%;
}
.cb-page-layout4 .main .part,
.cb-page-layout4 .main > .grid table {
  margin-right: 2.17391304%;
  margin-left: 2.17391304%;
  width: 95.65217391%;
}
.cb-page-layout4 .main > .slim {
  width: 47.87234043%;
}
.cb-page-layout4 .main > .slim h2,
.cb-page-layout4 .main > .slim .foot,
.cb-page-layout4 .main > .slim .part,
.cb-page-layout4 .main > .slim.grid table {
  margin-right: 4.44444444%;
  margin-left: 4.44444444%;
}
.cb-page-layout4 .main > .slim .part,
.cb-page-layout4 .main > .slim.grid table {
  width: 91.11111111%;
}
.cb-page-layout4 .main > .slim .tiny {
  width: 91.11111111%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.south .part,
.south > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.south .tiny {
  width: 21%;
}
.south > .slim {
  width: 25%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 8%;
  margin-left: 8%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 84%;
}
.south > .slim .tiny {
  width: 84%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.cb-page-layout4 .main {
  width: 102.17391304%;
  margin-left: -1.08695652%;
}
@media only screen and (max-width: 1023px) {
  .cb-page-layout2 .side div.wide {
    width: 66.66666667%;
  }
  .cb-page-layout2 .side div.slim {
    width: 33.33333333%;
  }
}
.cb-page-layout1 div.seam.slim {
  float: right;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.desk--svk a {
  margin-top: 30px;
  width: 48.33333333%;
  max-width: 475px;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #6f7071;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #6f7071;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #565757;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #626364 #494a4a #494a4a #626364;
  border-radius: 2px;
  background-color: #6f7071;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(60, 61, 62, 0.75);
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #626364 #494a4a #494a4a #626364;
  background-color: #777879;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #626364 #494a4a #494a4a #626364;
  background-color: #565757;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-medium.css.map */